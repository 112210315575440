import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const TripCarApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripCarlist`,data),//这里必须写清楚接口的注释说明
    saveTripCar: (data) => AppHttpKit.postJSON(`/v1/saveTripCar`,data),
    queryTripCarById: (data) => AppHttpKit.postJSON(`/v1/queryTripCarById/`,data),
    updateTripCar: (data) => AppHttpKit.postJSON(`/v1/updateTripCar/`,data),
    deleteTripCar: (data) => AppHttpKit.postJSON(`/v1/deleteTripCar/`,data),
    batchDeleteTripCar:(data) => AppHttpKit.postJSON(`/v1/batchDeleteTripCar/`,data),
    findTripCarDetail:(data)=>AppHttpKit.postJSON(`/v1/findTripCarDetail/`,data),
}
export default TripCarApi;
