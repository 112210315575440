<template>
  <div>
    <div>
      <el-form :inline="true" class="btnBox" @submit.native.prevent>
        <div class="search">
          <slot name="search"></slot>
          <el-form-item v-if="showSearch || showReset">
            <el-button type="primary" v-if="showSearch" @click="headSearch">查询</el-button>
            <el-button type="info" v-if="showReset" @click="reset">重置</el-button>
          </el-form-item>
        </div>

        <div class="buttonArea">
          <el-button type="primary" v-if="showAdd" @click="add">新增</el-button>
          <el-button type="danger" v-if="showDelete" @click="batchDelete">删除</el-button>
          <el-button type="primary" v-if="EShow" @click="exportExl" :loading="exportLoading">{{
              exportText
          }}</el-button>
          <slot name="buttons"></slot>
        </div>
      </el-form>
    </div>
    <el-table ref="multipleTable" :data="records.records" tooltip-effect="dark" style="width: 100%"
      @selection-change="handleSelectionChange" :header-cell-style="headClass" v-loading="loading" row-key="id">
      <el-table-column type="selection" width="55" v-if="showSelection" :key="Math.random()">
      </el-table-column>
      <slot name="content"></slot>
    </el-table>
    <div style="margin-top: 20px">
      <div class="pagenation">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizeList"
          :page-size="pageSize" :current-page="pageNum" layout="total, sizes, prev, pager, next, jumper"
          :total="records.total || records.totalRow" v-if="records.total > 0 || records.totalRow > 0">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import base from './baseTablesComponents';
export default {
  name: "TablesComponents",
  extends: base,
  props: {
    currentPage: {
      //当前页
      type: Number,
      default: 1,
    },

    search: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },

    EShow: {
      type: Boolean,
      default: false,
    },
    exportText: {
      type: String,
      default: "导出查询结果",
    },

    exportLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    headClass() {
      return "background:#FAFAFA;color:#666666";
    },
    headSearch() {
      this.pageNum = 1;
      this.doSearch();
    },
   
    reset() {
      //重置
      this.pageNum = 1;
      this.pageSize = 20;
      this.$emit("resetHandler");
      // this.$emit("searchHandler", {
      //   pageSize: this.pageSize,
      //   pageNum: this.pageNum,
      // });
    },
    add() {
      this.$emit("addHandler");
    },
    queryById(id) {
      this.$emit("queryByIdHandler", { id: id });
    },
    deleteById(id) {
      this.$confirmDialog("确定要进行当前操作吗?", () => {
        this.$emit("deleteByIdHandler", { id: id });
      });
    },
    ImportExl() {
      // this.$message.success('功能正在开发中！')
      this.$emit("ImportExl", true);
    },
    exportExl() {
      // this.$message.success('功能正在开发中！')
      this.$emit("exportExl", true);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row.id);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    currentPage2() {
      this.doSearch();
    },
    batchDelete() {
      if (this.multipleSelection.length == 0) {
        this.$errorMsg("请先选择一条记录，再进行当前操作");
        return;
      }
      this.$emit("batchDeleteHandler", this.multipleSelection);
    },
  },
};
</script>

<style lang="scss" scope>

.btnBox .el-form-item {
  margin-bottom: 10px !important;
}

.search {
  display: inline-block;
}

.search div {
  display: flex;
  margin-right: 16px;
}

.search-item {
  width: 360px;
  display: flex;
  justify-content: baseline;
}

.search-item span {
  padding: 0 6px;
  white-space: nowrap;
  line-height: 40px;
  display: inline-block;
  text-align: justify;
}

.buttonArea {
  .el-button {
    margin: 10px 20px 10px 0;
  }
}
</style>
<style>
.el-table__cell .el-button--text {
  padding: 0 !important;
}
</style>