<script>
import basePage from './basePage.vue'
import TableCompnent from "@/components/table/TablesComponents";
export default {
    extends: basePage,
    components: {
        TableCompnent
    },
    data() {
        return {
            canAdd: false,
            canDelete: false,
            canEdit: false,
            search: {
                pageNum: 1,
                pageSize: 20
            },
            loading: true,
            pageName: "",
        }
    },
    created() {
        this.pageName = this.$route.name;
        console.log(this.pageName);
        switch (this.pageName) {
            case 'companyLists'://运营公司管理
                this.canEdit = this.canDelete = this.isAdmin;
                break;
            case 'driverLists'://司机管理列表
            case 'tripCarLists'://车辆管理列表
                this.canAdd = this.canEdit = this.existsCompanyNo;
                this.canDelete = true;
                break;
        }
    }
}
</script>