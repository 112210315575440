<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent :loading="loading" :showSelection="false" :records="tableData" @addHandler="addHandler"
      @searchHandler="doSearch" @resetHandler="reset" @queryByIdHandler="editCarInfo" @deleteByIdHandler="deleteById"
      :showDelete="false" :showAdd="canAdd">
      <template slot="search">
        <el-form-item label="车牌号：">
          <el-input v-model.trim="search.carNum" placeholder="请输入车牌号..."></el-input>
        </el-form-item>
        <el-form-item label="所属车队">
          <el-input v-model.trim="search.motorcadeName" placeholder="请输入车队..."></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="carNum" label="车牌号">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row.id)">{{ scope.row.carNum }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="motorcadeName" label="所属车队" />
        <el-table-column prop="type" label="车辆类型" />
        <el-table-column prop="brand" label="品牌型号" />
        <el-table-column prop="maxNum" label="最大载客数" />
        <el-table-column prop="registerTime" label="注册日期">
          <template scope="s">
            {{ dateFormatType(s.row.registerTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="editCarInfo(scope.row.id)" v-if="canEdit">编辑</el-button>
            <el-button type="text" size="mini" @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>
  
<script>
import listPage from "../../base/listPage.vue";
import TripCarApi from "@/api/TripCarApi";
export default {
  name: "TripCarList",
  extends: listPage,
  data() {
    return {
      tableData: {},
      search: {
        carNum: "",
        motorcadeName: "",
      },
      page: {},
      loading: false,

    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      TripCarApi.search(this.search).then((resp) => {
        if (resp.code === '200') {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    addHandler() {
      this.navTo("/saveTripCar");
    },
    editCarInfo(id) {
      TripCarApi.queryTripCarById({ id: id }).then((resp) => {
        console.log(resp);
        if (resp.code === '200') {
          this.queryParam("saveTripCar", resp.data);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    toDetail(id) {
      this.navTo(`/tripCarDetail/${id}`);
    },
    deleteById(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          TripCarApi.deleteTripCar({ id: id }).then((resp) => {
            if (resp.code === '200') {
              this.$successMsg(resp.msg);
              this.doSearch(this.page);
            } else {
              this.$errorMsg(resp.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    batchDelete(params) {
      TripCarApi.batchDeleteTripCar(params).then((resp) => {
        if (resp.code === '200') {
          this.$successMsg(resp.msg);
          this.doSearch(this.page);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    dateFormatType(val) {
      if (!val) {
        return "";
      }
      var date = new Date(val);
      var year = date.getFullYear();
      var month = date.getMonth() + 1; // js从0开始取
      var date1 = date.getDate();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var second = date.getSeconds();
      if ((month + "").length === 1) {
        month = "0" + month;
      }
      if ((date1 + "").length === 1) {
        date1 = "0" + date1;
      }
      if ((hour + "").length === 1) {
        hour = "0" + hour;
      }
      if ((minutes + "").length === 1) {
        minutes = "0" + minutes;
      }
      if ((second + "").length === 1) {
        second = "0" + second;
      }

      return year + "-" + month + "-" + date1;
    },
  },
};
</script>
  <!--页面代码从这里结束拷贝-->